import React, { useContext } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { AppContext } from '../../util/app-context';

import Person from './Person';
import './Bio.css';
import tulja from '../../assets/vladimir_milic.jpg';
import niksa from '../../assets/niksa_milic.jpg';

const Bio = () => {
  const appCtx = useContext(AppContext);

  return (
    <div className='bio-div' id='bio'>
      <div className='bio-holder'>
        <ScrollAnimation
          animateIn='bounceInLeft'
          delay={100}
          animateOnce={true}
        >
          <h2 className='bio-header'>
            {appCtx.data.header.nav.bio[appCtx.lang]}
          </h2>
          <Person
            picture={tulja}
            name={'Vladimir Milić'}
            text={appCtx.data.bio.tulja[appCtx.lang]}
            contact={appCtx.data.header.nav.contact[appCtx.lang]}
            css={'tulja'}
          />
        </ScrollAnimation>
        <ScrollAnimation
          animateIn='bounceInRight'
          delay={100}
          animateOnce={true}
        >
          <Person
            picture={niksa}
            name={'Nikša Milić'}
            text={appCtx.data.bio.niksa[appCtx.lang]}
            contact={appCtx.data.header.nav.contact[appCtx.lang]}
            css={'niksa'}
            reverse={true}
          />
        </ScrollAnimation>
        <ScrollAnimation
          animateIn='bounceInLeft'
          delay={100}
          animateOnce={true}
        >
          {/*  <Person
            picture={nina}
            name={"Nikolina Jakovljević"}
            text={appCtx.data.bio.nani[appCtx.lang]}
            contact={appCtx.data.header.nav.contact[appCtx.lang]}
            css={"tulja nina"}
          /> */}
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Bio;
