import React, { useContext } from "react";
import { AppContext } from "../../../util/app-context";

import Service from "../Service";
import SubService from "../SubService";
import "./Areas.css";

const CivilLaw = () => {
  const appCtx = useContext(AppContext);
  const services = appCtx.data.services;

  return (
    <div className="civil-law">
      <Service
        service={services.civil[appCtx.lang]}
        key={services.civil[appCtx.lang]}
      />
      <SubService name={services.contract[appCtx.lang]} />
      <SubService name={services.estate[appCtx.lang]} />
      <SubService name={services.family[appCtx.lang]} />
      <SubService name={services.inheritance[appCtx.lang]} />
      <SubService name={services.will[appCtx.lang]} />
      <SubService name={services.compensation[appCtx.lang]} />
    </div>
  );
};

export default CivilLaw;
