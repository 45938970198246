import React, { useContext } from "react";

import { Link } from "react-scroll";
import { AppContext } from "../../util/app-context";
import scales from "../../assets/scales.png";
import boss from "../../assets/milivoje_milic.jpg";

import "./Intro.css";
import ScrollAnimation from "react-animate-on-scroll";

const Intro = () => {
  const appCtx = useContext(AppContext);

  return (
    <div className="intro" id="intro">
      <div className="intro-anim">
        <ScrollAnimation
          animateIn="fadeInDownBig"
          delay={500}
          animateOnce={true}
        >
          <img src={scales} alt="justice scales" id="justiceScales" />
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInUpBig"
          delay={2000}
          animateOnce={true}
        >
          <p id="intro-anim-title">Milić</p>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn" delay={1500} animateOnce={true}>
          <p id="lawFirm">{appCtx.data.intro.firm[appCtx.lang]}</p>
        </ScrollAnimation>
      </div>
      <h2>{appCtx.data.intro.h2[appCtx.lang]}</h2>
      <div className="intro-text">
        <p>{appCtx.data.intro.owner[appCtx.lang]}</p>
        <div className="boss-img">
          <img src={boss} alt="Milivoje Đ. Milić" className="boss" />
          <span>Milivoje Đ. Milić</span>
        </div>
      </div>
      <div className="button-holder">
        <button className="jump-to-contact">
          <span>
            <Link to="contact" spy={true} smooth={true} duration={300}>
              {appCtx.data.intro.btn[appCtx.lang]}{" "}
              <i className="fas fa-level-down"></i>
            </Link>
          </span>
        </button>
      </div>
    </div>
  );
};

export default Intro;
