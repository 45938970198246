import React, { useContext } from "react";
import { AppContext } from "../../../util/app-context";

import Service from "../Service";
import SubService from "../SubService";
import "./Areas.css";

const LabourLaw = () => {
  const appCtx = useContext(AppContext);
  const services = appCtx.data.services;

  return (
    <div className="labour-law">
      <Service
        service={services.labour[appCtx.lang]}
        key={services.labour[appCtx.lang]}
      />
      <SubService name={services.all[appCtx.lang]} />
      <SubService name={services.advice[appCtx.lang]} />
    </div>
  );
};

export default LabourLaw;
