import React, { useContext } from "react";
import { AppContext } from "../../../util/app-context";

import Service from "../Service";
import SubService from "../SubService";
import "./Areas.css";

const TortLaw = () => {
  const appCtx = useContext(AppContext);
  const services = appCtx.data.services;

  return (
    <div className="tort-law">
      <Service
        service={services.tort[appCtx.lang]}
        key={services.tort[appCtx.lang]}
      />
      <SubService name={services.defence[appCtx.lang]} />
      <SubService name={services.provision[appCtx.lang]} />
    </div>
  );
};

export default TortLaw;
