import React, { useContext } from "react";
import { Link } from "react-scroll";
import { AppContext } from "../../util/app-context";

import "./Footer.css";

const Footer = () => {
  const appCtx = useContext(AppContext);
  return (
    <footer>
      <div className="links-contact">
        <div className="links">
          {/* <span>
            <Link
              to="intro"
              spy={true}
              smooth={true}
              duration={300}
              offset={-100}
            >
              {appCtx.data.footer.top[appCtx.lang]}
            </Link>
          </span> */}
          <span>
            <Link to="services" spy={true} smooth={true} duration={300}>
              {appCtx.data.footer.links.practice[appCtx.lang]}
            </Link>
          </span>
          <span>
            <Link to="bio" spy={true} smooth={true} duration={300}>
              {appCtx.data.footer.links.bio[appCtx.lang]}
            </Link>
          </span>
          <span>
            <Link to="contact" spy={true} smooth={true} duration={300}>
              {appCtx.data.header.nav.contact[appCtx.lang]}
            </Link>
          </span>
        </div>
        <div className="contact">
          <h4>
            <i className="fal fa-map-marker"></i> Arsenija Čarnojevića 6, 22000
            Sremska Mitrovica
          </h4>
          <h4>
            <i className="fal fa-phone"></i>{" "}
            <a
              href={`tel:${appCtx.data.contact.map.number[appCtx.lang]}`}
              className="phone-link"
            >
              {appCtx.data.contact.map.number[appCtx.lang]}
            </a>
          </h4>
          <h4>
            <i className="fal fa-envelope"></i>{" "}
            <a
              href="mailto:lawofficemilic@gmail.com"
              className="mailer footer-mail"
              title="Email us!"
            >
              lawofficemilic@gmail.com
            </a>
          </h4>
        </div>
        <div className="dev">
          <p>
            {appCtx.data.footer.copyright[appCtx.lang]}{" "}
            <i className="fad fa-copyright"></i> 2021
          </p>
          <p>
            {appCtx.data.footer.dev[appCtx.lang]}:{" "}
            <a href="mailto:bobicaleks@yahoo.com">bobicaleks@yahoo.com</a>{" "}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
