import React, { useState } from "react";

const Person = ({ picture, name, text, contact, css, reverse }) => {
  const [hover, setHover] = useState(false);

  return (
    <>
      {reverse ? (
        <div className={css}>
          <div className="text-holder">
            <h3>{name}</h3>
            <p className="second-text">{text}</p>
            <span>
              {contact}:{" "}
              <a
                href="mailto:lawofficemilic@gmail.com"
                className="mailer"
                title="Email us!"
              >
                lawofficemilic@gmail.com
              </a>
            </span>
          </div>
          <div className="img-holder" onMouseLeave={() => setHover(false)}>
            <img src={picture} alt={name} onMouseOver={() => setHover(true)} />
            <div className={hover ? "img-email hoverImg" : "img-email"}>
              <a href="mailto:lawofficemilic@gmail.com" title="Email us!">
                <i className="far fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={css}>
            <div className="img-holder" onMouseLeave={() => setHover(false)}>
              <img
                src={picture}
                alt={name}
                onMouseOver={() => setHover(true)}
              />
              <div className={hover ? "img-email hoverImg" : "img-email"}>
                <a href="mailto:lawofficemilic@gmail.com" title="Email us!">
                  <i className="far fa-envelope"></i>
                </a>
              </div>
            </div>
            <div className="text-holder">
              <h3>{name}</h3>
              <p>{text}</p>
              <span>
                {contact}:{" "}
                <a
                  href="mailto:lawofficemilic@gmail.com"
                  className="mailer"
                  title="Email us!"
                >
                  lawofficemilic@gmail.com
                </a>
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Person;
