import React, { useContext } from "react";
import { AppContext } from "../../../util/app-context";

import Service from "../Service";
import SubService from "../SubService";
import "./Areas.css";

const AdministrativeLaw = () => {
  const appCtx = useContext(AppContext);
  const services = appCtx.data.services;

  return (
    <div className="administrative-law">
      <Service
        service={services.administrative[appCtx.lang]}
        key={services.administrative[appCtx.lang]}
      />
      <SubService name={services.procedures[appCtx.lang]} />
      <SubService name={services.disputes[appCtx.lang]} />
    </div>
  );
};

export default AdministrativeLaw;
