import React, { useContext } from "react";

import { AppContext } from "../../util/app-context";

const Info = () => {
  const appCtx = useContext(AppContext);
  return (
    <div className="information">
      <h5>
        <i className="far fa-city"></i> 22000 Sremska Mitrovica,{" "}
        <i className="fal fa-globe-europe"></i>{" "}
        {appCtx.data.contact.map.countryName[appCtx.lang]}
      </h5>
      <h5>
        <i className="fal fa-map-marker"></i>{" "}
        <a
          href="https://www.google.com/maps/place/Advokatska+kancelarija+Mili%C4%87/@44.9714362,19.6156236,15z/data=!4m5!3m4!1s0x0:0xa10d487916633e68!8m2!3d44.9714362!4d19.6156236"
          target="_blank"
          rel="noopener noreferrer"
        >
          Arsenija Čarnojevića 6
        </a>
      </h5>
      <h5>
        <i className="fal fa-phone"></i>{" "}
        <a href={`tel:${appCtx.data.contact.map.number[appCtx.lang]}`}>
          {appCtx.data.contact.map.number[appCtx.lang]}
        </a>
      </h5>
      <h5>
        <i className="fal fa-browser"></i>{" "}
        <a href="https://lawmilic.com/">lawmilic.com</a>
      </h5>
      <h5>
        <i className="fal fa-envelope"></i>{" "}
        <a href="mailto:lawofficemilic@gmail.com" title="Email us!">
          lawofficemilic@gmail.com
        </a>
      </h5>
      <p>{appCtx.data.contact.map.question[appCtx.lang]}</p>
    </div>
  );
};

export default Info;
