import React, { useState, useEffect } from "react";
import "./App.css";

import Header from "./components/Header/Header";
import Intro from "./components/Intro/Intro";
import Bio from "./components/Bio/Bio";
import Services from "./components/Services/Services";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import ToTopButton from "./components/ToTopButton/ToTopButton";
import Aside from "./components/Header/Aside";
import NavLinks from "./components/Header/NavLinks";
import Language from "./components/Language/Language";
import Loader from "./components/Loader/Loader";
import { AppContext } from "./util/app-context";
import { useBurger } from "./util/burger-hook";
import { useLanguage } from "./util/language-hook";
import { useData } from "./util/data-hook";

function App() {
  const { top, middle, bottom, BurgerReset, BurgerAnimate } = useBurger();
  const {
    sr,
    en,
    srButton,
    enButton,
    lang,
    changeLanguageHandler,
    selectLanguageHandler,
  } = useLanguage();
  const { data, loading } = useData();
  const [showAside, setShowAside] = useState(false);
  const [activeNav, setActiveNav] = useState("");

  const openAsideHandler = () => {
    setShowAside(true);
  };

  const closeAsideHandler = () => {
    setShowAside(false);
    BurgerReset();
    document.body.setAttribute("class", "");
  };

  useEffect(() => {
    const scrollHandler = () => {
      let header = document.querySelector("header");
      let intro = document.querySelector("#intro");
      let services = document.querySelector("#services");
      let bio = document.querySelector("#bio");

      if (
        window.scrollY >
        header.offsetHeight +
          intro.offsetHeight +
          services.offsetHeight +
          bio.offsetHeight -
          200
      ) {
        setActiveNav("contact");
      } else if (
        window.scrollY >
        header.offsetHeight + intro.offsetHeight + services.offsetHeight - 200
      ) {
        setActiveNav("bio");
      } else if (
        window.scrollY >
        header.offsetHeight + intro.offsetHeight - 200
      ) {
        setActiveNav("services");
      } else {
        setActiveNav("");
      }
    };

    window.addEventListener("scroll", scrollHandler);
  }, []);

  return (
    <AppContext.Provider
      value={{
        top: top,
        middle: middle,
        bottom: bottom,
        sr: sr,
        en: en,
        srButton: srButton,
        enButton: enButton,
        lang: lang,
        data: data,
        loading: loading,
        selectLanguageHandler: selectLanguageHandler,
        changeLanguageHandler: changeLanguageHandler,
        BurgerReset: BurgerReset,
        BurgerAnimate: BurgerAnimate,
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header
            open={openAsideHandler}
            aside={showAside}
            close={closeAsideHandler}
            active={activeNav}
          />
          {showAside && (
            <Aside aside={showAside}>
              <Language close={closeAsideHandler} aside={showAside} />
              <NavLinks close={closeAsideHandler} />
            </Aside>
          )}
          <>
            <Intro />
            <Services />
            <Bio />
            <Contact />
            <Footer />
            <ToTopButton />
          </>
        </>
      )}
    </AppContext.Provider>
  );
}

export default App;
